import { LinksFunction } from '@remix-run/node';
import { Link } from '@remix-run/react';
import styles from '@assets/css/common.css?url';
export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export async function loader() {
    throw new Response('Not found', { status: 404 });
}

export default function NotFound() {
    return <ErrorBoundary />;
}

export function ErrorBoundary() {
    return (
        <div className="error_con">
            <div className="error_in">
                <p className="error_tit">페이지를 찾을 수 없습니다.</p>
                <p className="error_copy">
                    요청하신 페이지의 주소가 잘못 입력되었거나,
                    <br />
                    주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
                </p>

                <div className="error_btn_con">
                    <Link to="/" className="com_btn error">
                        돌아가기
                    </Link>
                </div>
            </div>
        </div>
    );
}
